body {
  background-color: rgba(255, 255, 255, 0.705);
}

#box-padding {
  background-color: white;
  border-radius: 10px;

}

.aboutus-container {
  position: relative;
}

.image-wrapper {
  position: relative;
}

.aboutus-img {
  width: 100%;
  height: 230px !important;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.text-overlay h1 {
  margin: 0;
  color: white;
  font-size: 4rem;
}

.link-about {
  position: absolute;
  top: 60%;
  left: 10px;
  padding: 5px;
  color: white;
  font-size: 2rem;
}

.link-about a {
  color: #fff;
  text-decoration: none;
  margin-right: 5px;
  font-size: 20px;
}

.link-about .home:hover {
  text-decoration: underline;
  color: #4798bd;
 
}
#card-styyle {
  margin-bottom: 20px;
  margin-right: 20px;
}







#uncontrolled-tab-example {
  font-size: 40px;
}

.testimonial_image {
  border-radius: 50%;
  width: 100px !important;
  height: 90px;
  margin: auto;
  width: 16rem;

}

.paragraph {
  text-align: justify;
}

.txt {
  position: absolute;
  top: 40%;
  left: 42%;
}

.txt h1 {
  font-size: 55px;
  color: white;
}

.aboutus-img {
  height: 300px;
  width: 100%;
}

.construction {
  width: 100%;
  height: 350px;
  border-radius: 10px;

}

.cord-icon {
  color: orange;
  font-size: 40px;
  text-align: left;
}

.h5-txt {
  color: rgb(2, 104, 129);
  text-align: left;
}
.missiontext{
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-family: 500 !important;
}
.qualitytext{
  font-size: 16px !important;
}
.h2-txt {
  text-align: left;
}

.imgg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
 object-fit: cover;
}

.img-container2:hover {
  transform: scale(1.2);
}

.img-container2 {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 80%;
  height: 150px;
}.temaname{
  width: 80%;
  margin-top: 5px;
}

.img-caption {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  /* margin-bottom: 30px; */
}

.vission-mission {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1-txt {
  color: #4798bd;

}

/* Default tab title color */
#uncontrolled-tab-example .nav-link {
  color: black;
  font-weight: bold;
}


#uncontrolled-tab-example .nav-link:hover,
#uncontrolled-tab-example .nav-link.active {
  color: blue;
}

.tab-content {
  padding: 20px;
}

.paragraph h6 {
  color: #333;
  font-size: 1rem;
}

@media screen and (max-width:799px) {
  .aboutus-img {
    height: 250px;
    width: 100%;
  }

  .txt h1 {
    font-size: 35px;
  }

  .txt {
    position: absolute;
    top: 35%;
    left: 32%;
  }
}

.whychoose-bgblack {
  /* background: linear-gradient(to bottom right,
      rgba(255, 255, 255, 0.9),
      rgba(79, 169, 210, 0.8),
      rgba(100, 100, 100, 0.7),
      rgba(0, 0, 0, 0.9)); */
  padding: 10px;
  /* margin-top: 40px; */
}

#white-bg {
  background-color: white;
  color: black;
  position: relative;
}

/* Container styles */
#white-bg {
  background-color: #fff;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Column styles */
#card-styyle {

  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.3s ease;
 
}

#card-styyle:hover {

  transform: scale(1.1);
}

#card-styyle h3 {
  color: #4798bd;
}

h1 {
  font-size: 1.8 rem;
  margin-bottom: 10px;
}

p.imgs {
  font-size: 1rem;
  line-height: 1.6;
}
