.img{
    height: 300px;
    width: 100%;
}
.gallery-img{
    height: 300px;
    width: 100%;
}
.gallery-text{
    position: absolute;
    top: 40%;
    left: 45%;

}
.gallery-text>h1{
    font-size: 55px;
    color: white;
}
.gallery{
    margin-bottom: 20px;
}
.link-service{
    font-size: 30px;
    text-align: left;
}
.link-service a{
    color: white;
    text-decoration: none;
    padding: 5px;
    background-color:  transparent;
    border-radius: 10px;
}
.link-service a:hover{
    background-color: #07a3d3;
}
