.header-container {
  display: flex;
  justify-content: space-between; 
  align-items: center;
 
  padding: 0 20px; 
}

.logo {
  width: 180px;
  height: auto;
 margin-left: 60px; 
 
}
.contact-text>span{
  font-size: 16px;
}
.contact-info>.contact-text{
  font-size: 16px;
}

.contact-item-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px; 
  align-items: center;
  flex-wrap: nowrap; 
}

.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-text {
  font-size: 18px;
  margin: 0 6px;
}

.scanner img {
  width: 120px;
  height: 100px;
}
@media screen and (max-width: 499px) {

  .nav-link{
    color: black !important;
    text-align: left !important;
  }

}

@media screen and (max-width: 799px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  .logo {
    margin-bottom: 20px; /* Spacing below the logo */
    width: 150px; /* Adjust size for smaller screens */
  }

  .contact-item-wrapper {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    gap: 2px;
  }

  .contact-info {
    margin-bottom: 10px;
    margin-left: 0; /* Reset margin for stacked layout */
  }
  .nav-link{
    color: black;
    text-align: center;
  }

  .scanner img {
    width: 100px;
  }
}

.nav-link {
  color: white;
  font-size: 16px;
  margin: 5px;
  text-decoration: none;
}

.nav-link:hover {
  color: #4FA9D8;
}
