
.banner-input{
    float: left;
    width: 30%;
    text-align: center;
    margin: 10px;
}
.serch-icon{
    float: left;
    margin: 10px;
    width: 40px;
    background-color: rgb(3, 146, 182);
    color: white;
}
.h1-style{
    color:rgb(3, 146, 182);
    float: left;
}
.add-buttons{
    background-color: rgb(3, 146, 182);
    float: right;
    margin-bottom: 10px;
}
.paragraphs{
    color: red;
    text-align: center;
}
/* Styling for the table header */
.custom-header {
  background-color: #109cee; /* Blue background color */
  color: white; /* White text color */
}

/* Optional: Styling for table header cells */
.custom-header th {
  padding: 10px; /* Adjust padding as needed */
  text-align: left; /* Align text to the left */
}

  #input-border{
    
    border: 1px solid rgb(3, 146, 182);
    border-radius: 5px;
    
  }


  /* Admin Login */
  
  .adminlogin {
    font-size: 32px !important;
  }
  .adminlbtn {
    text-align: center;
  }
  .adminlbtn button {
    text-align: center;
    border-radius: 26px;
    width: 109px;
    background-color: #0779d6;
    font-family: math;
    --bs-btn-border-color: #4d4a8a;
    --bs-btn-active-bg: #4d4a8a;
    --bs-btn-hover-border-color: #4d4a8a;
    --bs-btn-hover-bg: #4d4a8a;
  }
  .Design h1 {
  text-align: left;
  color:  #4798bd;
  }

  /* dashbord */

  .dashboradbtn {
    height: 140px;
    width: 70%;
    background: #4798bd;
    color: white;
    border: none;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: all 0.3s ease; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
  }
  
  .dashboradbtn:hover {
    background: #357a9e; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
    transform: translateY(-2px); 
  }
  
  .dashboradbtn h4 {
    margin: 0;
  }
  
  