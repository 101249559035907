*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* .Package-Section{
    background: linear-gradient(to bottom right,
    rgba(255, 255, 255, 0.9),
    rgba(79, 169, 210, 0.8),
    rgba(100, 100, 100, 0.7),
    rgba(0, 0, 0, 0.9));
    padding: 120px;
} */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 2px solid #4798bd;
  padding: 8px;
  text-align: center;
}

  .col-span {
    text-align: center; 
    font-weight: bold; 
    font-size: 20px;
  
  }


  th.col-span1 {
    text-align: left; 
    font-weight: bold; 
    background-color: #4798bd;
    color: white;
    padding: 5px 10px; 
    line-height: 1.0;
}
.Package-Section h1{
margin: 30px;
  text-align: center;
}
  .table-bordered {
    border-collapse: collapse;
    width: 100%;
  }
  .table-bordered th, .table-bordered td {
    border: 2px solid black; /* Change color if needed */
    text-align: center;
    padding: 8px; /* Adjust padding if necessary */
  }
  .table-bordered th {
    background-color: #f2f2f2; /* Optional: header background color */
  }
  