* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.link-service{
    font-size: 30px;
    text-align: left;
}
.link-service a{
    color: white;
    text-decoration: none;
    padding: 5px;
    background-color:  transparent;
    border-radius: 10px;
}
.link-service a:hover{
    background-color: #07a3d3;
}



.services {
    height: 10%;
    width: 100%;
    font-size: 5rem;
    font-weight: bold;
    background: url("/public/Assets/background.jpg") center/cover no-repeat;
}

.heading>h1 {
    font-size: 55px;
    color: white;
}

.heading {
    position: absolute;
    top: 40%;
    left: 43%;
}

.services-img {
    height: 300px;
    width: 100%;
}

.text {
    margin: 0 auto;
    margin-top: 30px;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 3em;

}
.container p{
    text-align: left;
}

.text1 {
    font-size: 16px !important;
    text-align: left;
}
.text1>div>div>ul>li{
    font-size: 18px;
}
.ser-style{
    /* font-style: italic;
    text-align: left; */
    font-family: "Roboto", sans-serif;
    text-align: left;
    font-size: 30px;
}

@media screen and (max-width:799px) {
    .heading {
        position: absolute;
        top: 40%;
        left: 22%;
    }

}