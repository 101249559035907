.footer {
    display: flex;
    justify-content: space-around; 
    align-items: flex-start; 
    flex-wrap: wrap;
    background-color: rgba(49, 106, 131, 255);
    color: white;
    text-align: left;
    position: relative; 
}

.custom-button a{
    text-decoration: none;
    color: white;
    font-size: 16px;
}
.custom-button a:hover{
    text-decoration: underline;
}

.second, .third {
    width: 20%;
    margin: 10px; 
    /* border: 1px solid black; */
}
.payment{
    width: 30%;
  }
  .paymentterms{
    font-size: 14px;
  }
  .paymentterms>ul>li{
    font-size: 14px;
  }
.bhgdhg>li>a{
    font-size: 16px;
}
.bhgdhg>li{
    font-size: 16px; 
}

ul {
    padding: 0;
    margin: 0;
    list-style: none; 
    text-align: left; 
    font-size: 20px;
}


.social-media {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px; 
    position: absolute; 
    /* bottom: 10px; 
    right: 10px;  */
}

.social-media  {
    color: white;
    font-size: 30px; 
  
}


@media only screen and (max-width: 799px) {
    .footer {
        flex-direction: column; 
        align-items: stretch; 
    }

    .second, .third {
        width: 100%; 
        margin: 0; 
    }

    .second>.contact>h3, .third>.contact>h3 {
        font-size: 20px; 
        margin-bottom: 15px; 
    }
}
