*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.heading{
    position: absolute;
    top: 50%;
    left: 30%;
}
.heading h1{
    top: 40%;
    left: 40%;
    font-size: 55px;
     color: white;
}
.contact-img{
    width: 100%;
    height: 400px;
}
form{
    padding: 10px 20px;
    border: 1px solid  #4798bd;
}

.form-group>h4{
    color: #4798bd;
    margin-right: 15em;
}
.form-group label{
display: block;
font-weight: bold;
margin-bottom: 0.5em;
text-align: left;
}
.form-group input{
    padding: 0.5em;
    font-size: 1em;
    margin-bottom: 0.5em;
    border: 1px solid  #4798bd;
    width: 100%;
    border-radius: 5px;
}
.form-group input:focus{
  outline: none;
}
.form-group textarea{
    width: 100%;
    height: 125px;
    padding: 0.5em;
    font-size: 1.5em;
    resize: none;
}

button{
    padding: .5em 3.5em;
    margin-top: 0.5em;
    border: 1px solid   #4798bd;
    border-radius: 5px;
    background-color: #4798bd;
    font-size: 1em;
    transition: background-color 0.5s;
}
button:hover{
    background-color:  #7bcaee;
    ;
    color: white;
}
.address{
    display: flex;
    gap: 20px;
}
.phone{
    display: flex;
    gap: 20px;
}
.email{
    display: flex;
    gap: 20px;
}
.contents{
    display: flex;
    font-size: 2em;
    justify-content: center;
    align-items: center;
    height: 60px;
    gap: 13px;
    color: #4798bd;
}

.card-design{
    border: none;
    box-shadow: 0px 1px 10px 5px #ddd;
    height: 140px !important;
}

.row>h3{
    margin-top: 1em;
    color:  black;
}
.message{
    border: 1px solid#4798bd;
}
.link-service{
    font-size: 30px;
    text-align: left;
}
.link-service a{
    color: white;
    text-decoration: none;
    padding: 5px;
    background-color:  transparent;
    border-radius: 10px;
}
.link-service a:hover{
    background-color: #07a3d3;
}

  