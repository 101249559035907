body{
    background-color: #FFF8E7;
}
.slider-img{
    height: 550px;
    width:100%
}
.images{
    display: flex;   
}

.img1{
    height: 250px;
    width: 300px;
    margin-bottom: 30px;
}
.elipsescard{
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 205px;
}
.main-title{
    font-size: 25px;
    color: #4fa9d2;
    font-weight: 600;
    /* padding: 10px 0; */
}

.custom-font-size{
  font-size: 16px !important;
}
.link-about{
    font-size: 30px;
  color: black;
    float: left;
}
.about-img{
    width: 100%;
    height: 300px;
    border-radius: 15px;
}
.about-content{
    text-align: left;
    font-family: "Roboto";
}


.img-container {
     position: relative;
    width: 300px; 
    height: 250px;
 
 }
  .img-container >.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 .choose-img,
 .overlay-image {
   position: absolute;
   top: 0;
   left: 0;
   width: 70%;
   height: 200px;
   object-fit: cover; 
   border-radius: 50%;
}
.overly-image{
   height: 200px;
    margin: 80px; 
    margin-bottom: 40px;
   width: 70%;
   border-radius: 50%;
}
.choose-content{
    text-align: left;
}
.About{
    display: flex;
    
}
#background{

    background-color:#4798bd;
    color: white;
    padding: 20px;
    
}
.whychoosecontent>div>ul>li{
  font-size: 16px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.content{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.content h1{
    color: white;
    margin-top: 250px;
    
}
.content h2{
    color: white;
}
.logo{
    width: 50px;
    height: auto;
    margin-right: 10px;
}
.Navlinks{
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    color: block;
    text-decoration: none;
}
.Navlinks Link{
    color: block;
    text-decoration: none;
}
.img{
    width: 100%;
    height: 300px;
}
.i{
    height: 200px;
    width: 200px;
}
.carousel-indicators{
    display: none !important;
}
.carousel-style{
  text-align: left !important;
   margin-bottom: 18px; 
   width: 100%;
}

.img-container {
   
    overflow: hidden;
  }
  
  .img-caption {
    height: 100%;
    width: 100%;
   text-align: center;
    color: white;
   font-size: 18px;
   font-family: "Roboto", sans-serif;
 }

 .choose-img,
  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 200px;
    object-fit: cover; 
    border-radius: 50%;
}
.overly-image{
    height: 200px;
     margin: 80px; 
     margin-bottom: 40px;
    width: 70%;
    border-radius: 50%;
}

.fixed-icon {
  position: fixed;
  right: 20px; /* Margin from the right edge */
  bottom: 20px; /* Margin from the bottom edge */
  border: 1px solid #28A219;
  border-radius: 11px;
 
  background-color: #28A219; 
  z-index: 999; /* Ensure it stays on top */
}

.fixed-icon .icon {
  font-size: 30px; /* Icon size */
  color: white;
}
.cariuseldiv{
  width: 100%;
}
.carousel-control-next,.carousel-control-prev{
width: 9%;
color: gray;
}
.carouselcaption{
  /* border: 1px solid white; */
  width: 50%;
}

/* Adjustments for tablets and smaller screens */
@media screen and (max-width: 799px) {
  .fixed-icon {
    right: 15px; /* Adjusted margin for smaller screens */
    bottom: 15px; /* Adjusted margin for smaller screens */
    /* Reduced padding */
  }

  .fixed-icon .icon {
    font-size: 26px; /* Reduced icon size */
  }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 480px) {
  .fixed-icon {
    right: 10px; /* Further reduced margin */
    bottom: 10px; /* Further reduced margin */
   
  }

  .fixed-icon .icon {
    font-size: 22px; /* Further reduced icon size */
  }
}


.carousel-style{
    text-align: center;
     margin-bottom: 18px; 
     text-shadow: .1em .1em 0 hsl(200 50% 30%);
  }

  .img-container {
    position: relative;
    width: 300px; 
 }
  
  .choose-img,
  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 200px;
    object-fit: cover; 
    border-radius: 50%;
}
.overly-image{
    height: 200px;
     margin: 80px; 
     margin-bottom: 40px;
    width: 70%;
    border-radius: 50%;
}

.partener-icons
{
     width: 100%; 
    height: 110px;
}
.margin{
    height: 200px;
    margin-top: 15px;
}
.margin1{
     height: 200px;
    margin-top: 10px;
}
 
.carousel-caption {
    position: absolute;
    left: 5%;
    color: #fff;
    text-align: center;
    top: 40%;

}

@media screen and (max-width:799px) {
    .slider-img {
        height: 300px;
        width: 100%;
    }

    .carousel-caption {
        position: absolute;
        left: 17%;
        color: #fff;
        text-align: center;
        top: 20%;
    }
    .carousel-style {
        text-align: left;
        margin-bottom: 19px;
        font-size: 22px;
    }
    
    
}

.more-detailes a{
    text-decoration: none;
    color: blue;
}

.image-grow {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; 
}


  
  .img-container {
    position: relative; 
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .img-container:hover .img {
    transform: scale(1.1);
  }
  
  /* Overlay styling */
  .img-overlay {
    position: absolute;
    top: 0;
    left: -100%; 
    width: 100%; 
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
    transition: left 0.5s ease; 
    padding: 20px;
    text-align: center;
    border-radius: 10px;

  }

  .img-container:hover .img-overlay {
    left: 0; 
    
  }
  
  .img-caption {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: bold;
    
  }
  
  .anchortag {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
  }
  
  .anchortag:hover {
    text-decoration: underline;
  }
  
  .anchortag svg {
    margin-left: 5px;
  }
  


.image-container {
    position: relative;
  }
  
  .image-container img {
    width: 100%;
    height: 250px;
    object-fit: cover; 
  }
  
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; 
     }
     .text-overlay button{
        padding: 10px 20px;
        font-size: 22px;
        border: none;
}
.text-overlay button:hover{
    background-color: orange;
    color:  black;
}
  
  .text-overlay h6 {
    margin: 0;
    font-size: 1.5rem; 
    color: orange;
    margin: 10px;
  }
  
  .text-overlay h1 {
    margin: 0;
    font-size: 3rem; 
  }
  .section-contaner{
    background: linear-gradient(
      to bottom right, 
      rgba(255, 255, 255, 0.9), 
      rgba(79, 169, 210, 0.8), 
      rgba(100, 100, 100, 0.7), 
      rgba(0, 0, 0, 0.9) 
    );
  }
#card-styyle{
  height: 300px;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .section-contaner h1{
    font-size: 45px;
  }
  .anchortag{
    text-decoration: none;
   color: white;
  }



.section-container {
  padding: 20px;
  background: linear-gradient(
    to bottom right, 
    rgba(255, 255, 255, 0.9), 
    rgba(79, 169, 210, 0.8), 
    rgba(100, 100, 100, 0.7), 
    rgba(0, 0, 0, 0.9) 
  );
}

.text-center {
  text-align: center;
}
.owl-theme{
  height: auto;
}

.owl-theme .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.testimonial-content {
  margin-bottom: 10px;
}

.testimonial-author {
  text-align: right;
}





  /* Latest Projects */
  .section-contaners{
    padding: 20px;
    background-color: rgba(233, 228, 228, 0.658);
  }
  .img-container {
    position: relative; 
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .img {
    width: 100%;
    height: 200px;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .img-container:hover .img {
    transform: scale(1.1); /* Scale up the image slightly on hover */
  }
  
  /* Overlay styling */
  .img-overlay {
    position: absolute;
    top: 0;
    left: -100%; /* Start off-screen to the left */
    width: 100%; /* Full width of the parent container */
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Hide overflow */
    transition: left 0.5s ease; /* Slide effect */
    padding: 20px;
    text-align: center;
    border-radius: 10px;

  }
  .img-overlay>div{
    font-size: 16px;

  }
  
  .img-container:hover .img-overlay {
    left: 0; /* Move overlay into view on hover */
  }
  .img-overlay>h1{
    font-size: 18px;
  }
  .img-overlay>.img-description{
    font-size: 16px;
  }
  .img-caption {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: white;
  } 
  
 
  .img-description {
    font-size: 1rem;
  }

  
  /* Ensure the section has some space to slide */
  .section-contaners > .row {
    position: relative;
    left: 0;
    transition: left 0.5s ease-in-out;
  }
  
  
  .section-contaners {
    padding: 20px;
  }


  